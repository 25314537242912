import { ref, onMounted } from "vue";

export function useWindowSize() {
  const width = ref(0);
  const height = ref(0);

  const updateSize = () => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  };

  onMounted(() => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;

    window.addEventListener("resize", updateSize);
  });

  return { width, height };
}
